@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap");

.text {
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;

  font-family: "Work Sans";

  @media screen and (max-width: 800px) {
    font-size: 25px;
  }
}

.return {
  width: 343px;
  height: 48px;
  border: none;
  border-radius: 16px;
  background-color: #a499ca;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: "Work Sans";

  margin: auto;
  margin-top: 100px;
}
