@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap");

.container {
  width: 100vw;
  height: 100vh;
}
.buttonsContainer {
  display: flex;
  width: 60%;
  justify-content: space-around;
  height: 35%;
  a {
    text-decoration: none;
  }

  @media screen and (max-width: 800px) {
    margin-top: -5rem;
    width: 90%;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }

  @media screen and (max-height: 670px) {
    button {
      height: 40% !important;
    }
  }

  @media screen and (min-width: 800px) {
    button {
      height: 60% !important;
    }
  }
}

.primary {
  border: none;
  background: #a499ca;
  color: white;
  display: flex;
  align-items: center;
  z-index: 3;
  img {
    margin-right: 0.5rem;
  }
}

.secondary {
  border-color: #a499ca;
  color: #a499ca;
  background-color: white;
  text-decoration: none;
}

.desktopBoard {
  margin-top: 5rem;
}

.desktopMoves {
  font-size: 25px;
  padding-top: -2rem;
  width: 390px;
  // margin-bottom: -15rem;
  margin-top: -220px;
  @media screen and (min-width: 800px) {
    font-size: 23px;
    // margin-bottom: -5rem;
  }
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 800px) {
    font-size: 16px;
    width: 290px;
    margin-top: -230px;
    // margin-bottom: -5rem;
  }
}

.logo {
  width: 390px;
  display: flex;
  justify-content: center;
  margin-bottom: -15rem;
  margin-top: -10rem;

  img {
    width: 10%;
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
}

.desktopWon {
  width: 350px;
  height: 350px;
  margin: auto;
  @media screen and (max-width: 800px) {
    margin-top: 2rem;
    width: 125px;
    height: 125px;
  }
}

.wonMoves {
  width: 70%;
  display: flex;
  justify-content: space-between;

  span {
    text-decoration: none !important;
  }
}

.wonText {
  font-size: 30px;
  font-weight: bold;
  width: 50%;
  text-align: left;
  margin: auto;
  font-family: "Work Sans";

  @media screen and (max-width: 800px) {
    font-size: 20px;
    margin-top: -0.5rem;
    width: 70%;
  }
}

.biggerContainer {
  @media screen and (max-width: 800px) {
    height: 130%;
    flex-direction: column-reverse;
  }

  @media screen and (min-width: 800px) {
    height: 50%;
  }
}

.wonContainer {
  display: flex;
  justify-content: space-between;
  width: 60%;

  height: 400px;
  margin-top: 5rem;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    width: 100%;
    margin-bottom: 4rem;
  }
}

.statsContainer {
  display: flex;
  flex-direction: column;
  font-family: "Work Sans";
  width: 50%;
  justify-content: space-around;
  align-items: center;

  @media screen and (max-width: 800px) {
    width: 100vw;
    margin-top: 1rem;
  }

  .title {
    font-weight: 700;
    margin-bottom: -5rem;
    text-align: center;

    @media screen and (max-width: 800px) {
      font-size: 20px;
      margin: auto;
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  button {
    height: 48px;
    width: 343px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: "Work Sans";
    font-size: 16px;
    margin-bottom: -8rem;
    margin-top: -5rem;
    font-weight: 600;

    @media screen and (max-width: 800px) {
      font-size: 16px;
      margin-bottom: 0;
      margin-top: 1rem;
      height: 48px;
    }
  }

  .playAgain {
    text-align: center;
    span {
      text-align: center;
      text-decoration: underline;
      width: 100%;
      cursor: pointer;
      font-weight: 600;
    }
  }
}

.signUp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  text-align: center;

  @media screen and (min-width: 800px) {
    height: 100%;
    margin-top: -5rem;
  }

  @media screen and (max-width: 800px) {
    font-size: 16px;
    margin-top: 1rem;
  }

  a {
    height: 100%;

    text-decoration: none;
  }

  button {
    height: 48px;
    width: 343px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: "Work Sans";
    font-size: 16px;
    margin-top: 1.5rem;
    font-weight: 600;

    @media screen and (max-width: 800px) {
      font-size: 16px;
    }
  }

  div {
    width: 55%;
    font-family: "Work Sans";
    font-size: 25px;
    font-weight: 600;
    color: #5b7cbe;

    @media screen and (max-width: 800px) {
      font-size: 16px;

      width: 100%;
      text-align: center;

      button {
        height: 35px;
      }
    }
  }
}

.opacity {
  span {
    transform: translateY(50%);
  }
}

.normalMoves {
  span {
    transform: translateY(50%) !important;
  }
}
