@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@600&display=swap");

.title {
  font-family: "Work Sans";
  font-weight: 700;
  font-size: 40px;

  @media screen and (max-width: 800px) {
    font-size: 20px;
    margin-bottom: 1rem;
  }
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 90vh;
  justify-content: space-around;
  font-family: "Work Sans";

  @media screen and (max-width: 800px) {
    justify-content: center;
  }
}

.text {
  font-size: 30px;
  text-align: center;
  width: 70%;
  color: #5b7cbe;
  font-weight: 600;

  @media screen and (max-width: 800px) {
    width: 90%;
    font-size: 16px;
    margin-bottom: 6rem;
  }
}

.image {
  width: 15%;
  @media screen and (max-width: 800px) {
    width: 35%;
    margin-bottom: 0.5rem;
  }
}

.buttonsContainer {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 60%;
  justify-content: space-around;
  input[type="file"] {
    display: none;
  }
  font-size: 16px;

  @media screen and (max-width: 800px) {
    margin-top: -5rem;
    width: 90%;
    justify-content: space-around;
    align-items: center;
    flex-direction: column-reverse;
    height: 25%;
  }
}

.customUpload {
  width: 343px;
  height: 48px;
  border: none;
  border-radius: 16px;
  background-color: #a499ca;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  cursor: pointer;

  @media screen and (max-width: 800px) {
    width: 70%;
  }
}

.skip {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  /* identical to box height */

  text-align: center;
  text-decoration-line: underline;

  /* White */

  color: #ffffff;
  margin: 1rem;
  @media screen and (max-width: 800px) {
    width: 70%;
  }
}
