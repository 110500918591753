.sneakPeak {
  position: absolute;
  top: 5%;
  left: 2%;
  text-align: center;
  font-size: 15px;

  img {
    width: 50%;
    cursor: pointer;
  }

  @media screen and (max-width: 800px) {
    width: 30%;
    left: 1%;
  }

  .sneakPeakText {
    font-family: Work Sans;
    font-weight: 700;
    margin-top: 0.3rem;
    font-size: 25px;

    @media screen and (max-width: 800px) {
      font-size: 15px;
    }
  }
}

.desktopIcons {
  position: absolute;
  top: 5%;
  right: 2%;
  text-align: center;
  font-size: 15px;

  @media screen and (max-width: 800px) {
    top: 4%;
  }
}

.nonMobile {
  @media screen and (max-width: 800px) {
    display: none;
  }
}

.mobile {
  @media screen and (min-width: 800px) {
    display: none;
  }

  width: 20%;

  img {
    width: 60%;
  }
  position: absolute;
  top: 5%;
  right: 0%;
  text-align: center;
  font-size: 15px;
}

.mobileIcons {
  width: 80%;
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  .singleMobileIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
}

.filter {
  filter: brightness(0.5);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal {
  position: absolute;
  top: 15%;
  left: 25%;
  width: 50%;
  height: 75vh;
  background: #a499ca;
  border-radius: 56px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: "Work Sans";

  img {
    width: 5%;
    margin-right: 1.5rem;

    @media screen and (max-width: 800px) {
      margin-right: 1rem;
      width: 15%;
    }
  }

  @media screen and (max-width: 800px) {
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 0;
  }
}

.closeIcon {
  width: 90%;
  padding: 10px;
  justify-content: flex-end;
  display: flex;
  margin-top: 2rem;

  @media screen and (max-width: 800px) {
    height: 5%;
  }

  img {
    cursor: pointer;

    @media screen and (max-width: 800px) {
      width: 10%;
    }
  }
}

.title {
  width: 100%;
  font-family: "Work Sans";
  font-weight: 600;
  display: flex;
  font-size: 30px;
  //   margin-top: 2rem;
  text-align: center;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 800px) {
    margin-top: 2rem;
    width: 80%;
    text-align: left;
  }
}

.content {
  width: 80%;
  margin: auto;
  margin-top: 2.5rem;
  font-size: 20px;
  font-weight: 600;

  @media screen and (max-width: 800px) {
    margin-top: 5rem;
  }
}
