@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap");

.container {
  width: 90%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Work Sans";
}

.buttonsContainer {
  display: flex;
  width: 60%;
  justify-content: space-around;
  input[type="file"] {
    display: none;
  }

  @media screen and (max-width: 800px) {
    margin-top: -5rem;
    width: 90%;
    justify-content: space-around;
    align-items: center;
    flex-direction: column-reverse;
    height: 25%;
  }
}

.button {
  width: 343px;
  height: 48px;
  border: none;
  border-radius: 16px;
  background-color: #a499ca;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;

  @media screen and (max-width: 800px) {
    width: 90%;
    margin: 1rem auto;
  }
}

.secondary {
  border: none;
  background: white;
  color: #a499ca;
  text-decoration: none;
}

.image {
  @media screen and (max-width: 800px) {
    width: 80%;
  }
  margin-bottom: 50px;
  width: 30%;
}
